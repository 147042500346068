import { ElMessage } from 'element-plus'

export const template = {
    state: () => ({
        template: {},
        templates:[]
    }),
    getters: {
        template({template}){
            return template
        },
        templates({templates}){
            return templates
        }
    },    
    mutations:{
        templates(state,payload){
            state.templates = payload
        },
        template(state,payload){
            state.template = payload
        },
        addTemplate(state,payload){
            state.templates = [payload,...state.templates]
        },
        deleteTemplate(state,payload){
            state.templates = state.templates.filter(template => {
                if (template._id === payload) return false
                return template
            })
        },
        updateTemplate(state,payload){
            state.templates = state.templates.map(template => {
                if (template._id == payload._id) return payload 
                return template
            })
        }        
    },


    actions:{        
        getTemplate({dispatch},payload){
            return dispatch('getAxios',{
                url: `template/get/${payload}`
            })
        },
        
        async getTemplates({dispatch,commit}){
            let res = await dispatch('getAxios',{
                url: 'template'
            })
            if (res.status === 200){
                commit('templates',[...res.data])
            }
        },

        async addTemplate({dispatch,commit},payload){
            let res = await dispatch('postAxios',{
                url: 'template',
                data: payload
            })
            if (res.status === 201){
                commit('addTemplate',{...res.data})
                ElMessage({
                    message: 'Yangi shartnoma shabloni qo`shildi',
                    type: 'success',
                })
            }
        },
        async saveTemplate({dispatch,commit},payload){
            let res = await dispatch('putAxios',{
                url: 'template',
                data: payload
            })
            if (res.status === 200){
                commit('updateTemplate',{...res.data})
                ElMessage({
                    message: 'Shablon yangilandi',
                    type: 'success',
                })
            }
        },           
        async generatePdf({dispatch},payload){
            await dispatch('postAxios',{
                url: 'template/addreport',
                data: payload
            })
        }     
    }
}