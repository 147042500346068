import { ElMessage } from "element-plus";

import { addZero, convertFullDate } from "@/store/helpers";

export const testproccess = {
  state: () => ({
    testproccesss: [],
    testproccess: {
      type: "",
    },
    countTestproccess: 0,
    testproccessTitle: "Test jarayon",
    resultestproccess: [],
    resultList: ["Bo`sh", "O`tdi", "O`tmadi", "Alohida qabul", "DTM qabul"],
  }),
  getters: {
    resultList({ resultList }) {
      return resultList;
    },
    resultestproccess({ resultestproccess }) {
      return resultestproccess;
    },
    testproccessTitle(state) {
      return state.testproccessTitle;
    },
    countTestproccess(state) {
      return state.countTestproccess;
    },
    testproccesss(state) {
      return state.testproccesss.map((t) => {
        let time = new Date(t.time);
        t.time = `${addZero(time.getHours())}:${addZero(time.getMinutes())}`;
        return t;
      });
    },
    testproccess(state) {
      return state.testproccess;
    },
    activeTestproccesss(state) {
      return state.testproccesss.filter(
        (testproccess) => testproccess.status == 0
      );
    },
  },
  mutations: {
    countTestproccess(state, payload) {
      state.countTestproccess = payload;
    },
    testproccesss(state, payload) {
      state.testproccesss = payload;
    },
    testproccess(state, payload) {
      state.testproccess = payload;
    },
    newTestproccess(state, payload) {
      state.testproccesss = [payload, ...state.testproccesss];
      state.countTestproccess += 1;
    },
    saveTestproccess(state, payload) {
      state.testproccesss = state.testproccesss.map((t) => {
        if (t._id == payload._id) return payload;
        return t;
      });
    },
    deleteTestproccess(state, payload) {
      state.testproccesss = state.testproccesss.filter((testproccess) => {
        if (testproccess._id == payload) return false;
        return testproccess;
      });
      state.countTestproccess -= 1;
    },
    removeResultTestproccess(state, payload) {
      state.resultestproccess = state.resultestproccess.filter((result) => {
        if (result._id == payload) return false;
        return result;
      });
      state.countTestproccess -= 1;
    },
    resultestproccess(state, payload) {
      state.resultestproccess = payload.map((person) => {
        person.end = person.end ? convertFullDate(person.end) : person.end;

        if (person.result) {
          person.allballs = person.result.allballs;
          person.balls = person.result.balls || 0;
        }

        return person;
      });
    },
    upResultTestproccess(state, payload) {
      state.resultestproccess = state.resultestproccess.map((result) => {
        if (result.student._id == payload._id) {
          result.student.result = payload.result;
          return { ...result };
        }
        return result;
      });
    },
  },
  actions: {
    async getTestproccesss(context, payload) {
      context.commit("setLoading", true);
      let res = await context.dispatch("getAxios", {
        url: "testproccess",
        search: payload,
      });
      // console.log(res.data.proccess);
      context.commit("setLoading", false);
      context.commit("testproccesss", res.data.proccess);
      context.commit("countTestproccess", res.data.count);
    },
    async changeStatusTestproccess(context, payload) {
      let res = await context.dispatch("getAxios", {
        url: `testproccess/change/${payload._id}/${payload.status}`,
      });
      if (res.status == 200) {
        if (payload.status == 2) {
          context.commit("deleteTestproccess", res.data._id);
          ElMessage({
            message: `${context.state.testproccessTitle} o'chirildi`,
            testproccess: "warning",
          });
        } else {
          context.commit("saveTestproccess", res.data);
          ElMessage({
            message: `${context.state.testproccessTitle} holati o'zgartirildi`,
            testproccess: "success",
          });
        }
      }
    },
    async editTestproccess(context, payload) {
      return await context.dispatch("getAxios", {
        url: `testproccess/edit/${payload}`,
      });
    },
    async saveTestproccess(context, payload) {
      let res = await context.dispatch("putAxios", {
        url: `testproccess`,
        data: payload,
      });

      if (res.status == 200) {
        context.commit("saveTestproccess", res.data);
        ElMessage({
          message: `${context.state.testproccessTitle} yangilandi`,
          testproccess: "success",
        });
      }
    },
    async deleteFileTestproccess({ dispatch }, payload) {
      return dispatch("postAxios", {
        url: "testproccess/files/delete",
        data: payload,
      });
    },
    async addTestproccess(context, payload) {
      let res = await context.dispatch("postAxios", {
        url: "testproccess",
        data: payload,
      });
      if (res.status == 201) {
        ElMessage({
          message: `Yangi ${context.state.testproccessTitle.toLowerCase()} qo'shildi`,
          testproccess: "success",
        });
        context.commit("newTestproccess", res.data);
      }
    },

    async getTestproccesssOlympic({ dispatch, commit }) {
      commit("setLoading", true);
      let res = await dispatch("getAxios", { url: "testproccess/alympik" });
      if (res.status == 200) {
        commit("testproccesss", res.data);
        commit("setLoading", false);
      }
    },
    excelTestproccess({ dispatch }, payload) {
      return dispatch("getAxios", {
        url: `testproccess/excel/${payload.id}`,
        search: payload.search,
      });
    },
    async removeResultTestproccess({ dispatch, commit }, payload) {
      let res = await dispatch("deleteAxios", `result/delete/${payload}`);
      if (res.status == 200) {
        commit("removeResultTestproccess", payload);
        ElMessage({
          message: `Natija o'chirildi`,
          testproccess: "warning",
        });
      }
    },

    async getResultTestproccess({ dispatch, commit }, payload) {
      commit("setLoading", true);
      let res = await dispatch("getAxios", {
        url: `testproccess/onecheck/${payload.id}`,
        search: payload.search,
      });
      console.log(res.data.result);
      commit("resultestproccess", res.data.result);
      commit("testproccess", res.data.testprocces);
      commit("countTestproccess", res.data.count);

      commit("setLoading", false);
    },
    async changeResult({ dispatch, commit }, payload) {
      let res = await dispatch("getAxios", {
        url: `cabinet/result/${payload._id}/${payload.result}`,
      });
      if (res.status == 200) {
        commit("upResultTestproccess", payload);
        ElMessage({
          message: `Imtihon natijani o'zgartirildi`,
          testproccess: "warning",
        });
      }
    },
  },
};
