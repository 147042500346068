import { ElMessage } from "element-plus";

export const filial = {
  state: () => ({
    filialsCount: 0,
    filials: [],
  }),
  getters: {
    filialsCount({ filialsCount }) {
      return filialsCount;
    },
    filials({ filials }) {
      return filials;
    },
    activeFilials({ filials }) {
      return filials.filter((filial) => filial.status == 0);
    },
  },
  mutations: {
    filials(state, payload) {
      state.filials = payload;
    },
    filialsCount(state, payload) {
      state.filialsCount = payload;
    },
    addFilial(state, payload) {
      state.filials = [payload, ...state.filials];
    },
    deleteFilial(state, payload) {
      state.filials = state.filials.filter((filial) => {
        if (filial._id === payload) return false;
        return filial;
      });
    },
    updateFilial(state, payload) {
      state.filials = state.filials.map((filial) => {
        if (filial._id == payload._id) return payload;
        return filial;
      });
    },
  },

  actions: {
    async getFilials({ dispatch, commit }, payload) {
      let res = await dispatch("getAxios", {
        url: "filial",
        search: payload,
      });
      // console.log(res.data);
      commit("filials", [...res.data.filial]);
      commit("filialsCount", res.data.count);
    },

    getFilial({ dispatch }, payload) {
      return dispatch("getAxios", {
        url: `filial/get/${payload}`,
      });
    },

    async addFilial({ dispatch, commit }, payload) {
      let res = await dispatch("postAxios", {
        url: "filial",
        data: payload,
      });
      if (res.status === 201) {
        commit("addFilial", { ...res.data });
        ElMessage({
          message: "Yangi fan qo`shildi",
          type: "success",
        });
      }
    },
    async saveFilial({ dispatch, commit }, payload) {
      let res = await dispatch("putAxios", {
        url: "filial",
        data: payload,
      });
      if (res.status === 200) {
        commit("updateFilial", { ...res.data.filial });
        ElMessage({
          message: "Filial yangilandi",
          type: "success",
        });
      }
    },
    async statusFilial({ dispatch, commit }, payload) {
      let res = await dispatch("getAxios", {
        url: `filial/status/${payload}`,
      });
      if (res.status === 200) {
        commit("updateFilial", { ...res.data });
        ElMessage({
          message: "Filial yangilandi",
          type: "success",
        });
      }
    },
  },
};
