import { ElMessage } from 'element-plus'
import router from '@/router'
export const testpack = {
    state: () => ({
        testpacks:[],
        countTestpack:0,
        players:[
            {
                t:'Olimpiadachilar',
                v:'olympian'
            },
            {
                t:'Guruhlar',
                v:'group'
            },
            {
                t:'Sinovchilar',
                v:'testers'
            }
        ],
        playersList:{
            'olympian': 'Olimpiadachilar',
            'group': 'Guruhlar',
            'testers': 'Sinovchilar'
        }
    }),
    getters:{
        playersList({playersList}){
            return playersList
        },
        players({players}){
            return players
        },
        countTestpack(state){
            return state.countTestpack
        },
        testpacks(state){
            return state.testpacks.filter(testpack => testpack.status !==2)
        },
        activeTestpacks(state){
            return state.testpacks.filter(testpack => testpack.status == 0)
        },
        activeSortTestpacks(state){
            let testpacks = state.testpacks.filter(testpack => testpack.status == 0)
            return testpacks.sort()
        }
    },
    mutations:{
        countTestpack(state,payload){
            state.countTestpack = payload
        },
        testpacks(state,payload){
            state.testpacks = payload
        },
        newTestpack(state,payload){
            state.testpacks = [payload,...state.testpacks]
            state.countTestpack += 1
        },
        saveTestpack(state,payload){
            let index = state.testpacks.findIndex(dep => dep._id == payload._id)
            if (index !==-1){
                
                state.testpacks[index] = payload
            }
        },
        deleteTestpack(state,payload){
            state.testpacks = state.testpacks.filter(testpack => {
                if (testpack._id == payload) return false
                return testpack
            })
            state.countTestpack -= 1
        }
    },
    actions:{
        async getTestpacks(context,payload){
            context.commit('setLoading',true)
            let res = await context.dispatch('getAxios',{url:'testpack',search:payload})
            if (res.status == 200){
                context.commit('testpacks',res.data.packs)
                context.commit('countTestpack',res.data.count)
                context.commit('setLoading',false)
            }
        },

        async addTestpack(context,payload){
            let res = await context.dispatch('postAxios',{url:'testpack',data: payload})
            if (res.status == 201) {
                router.push('/settings/testpacks')
                ElMessage({
                    message: 'Yangi test to`plam qo`shildi',
                    type: 'success',
                })
            }
        },

        async changeStatusTestpack(context,payload){
            let res = await context.dispatch('getAxios',{url:`testpack/change/${payload._id}/${payload.status}`})
            if (res.status == 200) {
                context.commit('saveTestpack',res.data)
                ElMessage({
                    message: 'Test to`plam holati o`zgartirildi',
                    type: 'success',
                })
            }
        },
        async editTestpack(context,payload){
            return await context.dispatch('getAxios',{url:`testpack/edit/${payload}`})
        },
        async getTestpack(context,payload){
            return await context.dispatch('getAxios',{url:`testpack/get/${payload}`})
        },
        async saveTestpack(context,payload){
            let res = await context.dispatch('putAxios',{url:`testpack`,data: payload})
            if (res.status == 200){
                context.commit('saveTestpack',res.data)
                router.push('/settings/testpacks')
                ElMessage({
                    message: 'Test to`plam yangilandi',
                    type: 'success',
                })
            }
        },
        async removeTestpack(context,payload){
            let res = await context.dispatch('deleteAxios',`testpack/delete/${payload}`)
            if (res.status == 200){
                context.commit('deleteTestpack',payload)
                ElMessage({
                    message: 'Test to`plam o`chirildi',
                    type: 'warning',
                })
            }
        },
        async removeTestpackFile(context,payload){
            return await context.dispatch('postAxios',
                {url:`testpack/files/delete`,
                data: {resFile:payload}
            })
        },
        getThemesBySubject({dispatch},payload){
            return dispatch('getAxios',{url:`testpack/${payload}`})
        }
    }
}