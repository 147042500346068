import { ElMessage } from 'element-plus'

export const kvitansiya    = {
    state: () => ({
        kvitansiyas: [],
        kvitansiyaTitle:'To`lovlar',
        countKvitansiyas:0
    }),
    getters: {
        kvitansiyas({kvitansiyas}){
            return kvitansiyas
        },
        countKvitansiyas({countKvitansiyas}){
            return countKvitansiyas
        }
    },

    // mutations
    mutations:{
        addKvitansiyas(state,payload){
            state.kvitansiyas = [payload,...state.kvitansiyas.map(kvitansiya => {
                return {...kvitansiya,status:1}
            })]
        },
        countKvitansiyas(state,payload){
            state.countKvitansiyas = payload
        },
        updateKvitansiya(state,payload){
            state.kvitansiyas = state.kvitansiyas.map(kvitansiya => {
                if (payload._id == kvitansiya._id) {
                    let salary = 0 
                    payload.payments.forEach(pay => {
                        salary+=pay.salary
                    })
                    return {...payload,salary}
                }
                return kvitansiya
            })
        },
        statusKvitansiya(state,payload){
            state.kvitansiyas = state.kvitansiyas.map(kvitansiya => {
                if (kvitansiya._id == payload._id) return payload
                kvitansiya.status = 1
                return kvitansiya
            })
        },
        kvitansiyas(state,payload){
            state.kvitansiyas = [...payload.map(item => {
                let d = new Date(item.createdAt)
                item.createdAt = `${d.getHours()<10?'0'+d.getHours():d.getHours()}:${d.getMinutes()<10?'0'+d.getMinutes():d.getMinutes()} ${d.getDate()<10?'0'+d.getDate():d.getDate()}/${d.getMonth()+1<10?'0'+(d.getMonth()+1):(d.getMonth()+1)}/${d.getFullYear()}`
                item.salary = 0 
                item.payments.forEach(pay => {
                    item.salary+=pay.salary
                })
                return item
            })]
        }
    },


    actions:{
        async getKvitansiyas({dispatch, commit},payload){
            let res = await dispatch('getAxios',{url:'kvitansiya',search:payload})
            if (res.status === 200){
                commit('kvitansiyas',res.data.kvitansiya)
                commit('countKvitansiyas',res.data.count)
            }
        },
        async getKvitansiya({dispatch},payload){
            return await dispatch('getAxios',{url:`kvitansiya/get/${payload}`})
        },
        async addKvitansiya({dispatch,commit,state},payload){
            let res = await dispatch('postAxios',{url:`kvitansiya/${payload._id}`,data:payload})
            if (res.status === 200){
                commit('updateKvitansiya',res.data)
                ElMessage({
                    message: `Yangi ${state.kvitansiyaTitle.toLowerCase()} qo'shildi`,
                        type: 'success',
                    })
            }
        },
        async statusKvitansiya({dispatch,commit,state},payload){
            let res = await dispatch('getAxios',{url:`kvitansiya/status/${payload}`})
            if (res.status == 200){
                commit('statusKvitansiya',res.data)
                ElMessage({
                    message: `${state.kvitansiyaTitle} holati o'zgartirildi`,
                        type: 'success',
                    })
            }
        },
        async saveKvitansiya({dispatch,commit,state},payload){
            if (payload){
                let res = await dispatch('putAxios',{url:'kvitansiya',data:payload})
                if (res.status === 200){
                    commit('updateKvitansiya',res.data)
                    ElMessage({
                        message: `${state.kvitansiyaTitle} holati o'zgartirildi`,
                            type: 'success',
                        })
                }
            }
        },
        
    }
}