
export const notification = {
    state: () => ({
        notification: {}
    }),
    getters: {
        notification(state){
            return state.notification
        }
    },
    mutations:{
        notification(state,payload){
            state.notification = payload
            setTimeout(()=>{
                state.notification = {}
            },3000)
        }
    },
    actions:{
        notification(context,payload){
            context.commit('notification',payload)
        }
    }
}