const addZero = (val) => val <= 9 ? `0${val}`: val

export const convertDate = (date) => {
  let d = new Date(date)  
  return `${addZero(
    d.getDate()
  )}/${addZero(d.getMonth() + 1)}/${d.getFullYear()}`;
}
export const convertFullDate = (date) => {
  let d = new Date(date)  
  return `${addZero(d.getDate())}/${addZero(d.getMonth() + 1)}/${d.getFullYear()} ${addZero(d.getHours())}:${addZero(d.getMinutes())}`;
}

export const compare = (a, b) => {
  if (a.title < b.title) {
    return -1;
  }
  if (a.title > b.title) {
    return 1;
  }
  return 0;
}

import router from "@/router";
import store from "@/store";
import { ElMessage } from 'element-plus';
export const checkAccess = (slug,crud,redirect = 1) => { 
  if (store.getters.role == 'admin') return true

  if (store.getters.menu.some((item) => item.page.slug == slug && item.cruds.some((cr) => cr == crud || crud == 'all' && store.getters.menu.some((item) => item.page.slug == slug))
    )) return true 
    else {
      if (redirect == 1){
        ElMessage({
          type:'error',
          message: 'Sizga bu sahifaga ruxsat yo`q'
        })
        router.push('/')
      } else {
        return false
      }
    }
  }


 export const dynamicSort = (property) => {
    var sortOrder = 1;
    if(property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    return function (a,b) {
        var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
        return result * sortOrder;
    }
}