import { ElMessage } from 'element-plus'

export const theme = {
    state: () => ({
        themes:[],
        theme:{},
        countTheme:0
    }),
    getters:{
        countTheme(state){
            return state.countTheme
        },
        themes(state){
            return state.themes.filter(theme => theme.status !==2)
        },
        theme(state){
            return state.theme
        },
        activeThemes(state){
            return state.themes.filter(theme => theme.status == 0)
        }
    },
    mutations:{
        countTheme(state,payload){
            state.countTheme = payload
        },
        themes(state,payload){
            state.themes = payload
        },
        theme(state,payload){
            state.theme = payload
        },
        newTheme(state,payload){
            state.themes = [payload,...state.themes]
            state.countTheme += 1
        },
        newThemes(state,payload){
            state.themes = [...payload,...state.themes]
            state.countTheme += payload.length
        },
        saveTheme(state,payload){
            let index = state.themes.findIndex(dep => dep._id == payload._id)
            if (index !==-1){
                
                state.themes[index] = payload
            }
        },
        deleteTheme(state,payload){
            state.themes = state.themes.filter(theme => {
                if (theme._id == payload) return false
                return theme
            })
            state.countTheme -= 1
        }
    },
    actions:{        
        async getThemes(context,payload){
            context.commit('setLoading',true)
            let res = await context.dispatch('getAxios',{url:'theme',search:payload})
            if (res.status == 200){
                context.commit('setLoading',false)
                context.commit('themes',res.data.themes)
                context.commit('countTheme',res.data.count)
            }
        },
        getAllThemes(context){
            return context.dispatch('getAxios',{url:'theme'})
        },
        async changeStatusTheme(context,payload){
            let res = await context.dispatch('getAxios',{url:`theme/status/${payload._id}/${payload.status}`})
            if (res.status == 200) {
                context.commit('saveTheme',res.data)
                ElMessage({
                    message: payload.status == 2 ? 'Mavzu o`chirildi' :'Mavzu holati o`zgartirildi',
                    type: 'success',
                })
            }
        },
        async getThemeBySubject({dispatch},payload){
            return await dispatch('getAxios',{url:`theme/subject/${payload}`})
        },
        async editTheme(context,payload){
            return await context.dispatch('getAxios',{url:`theme/get/${payload}`})
        },
        async saveTheme(context,payload){
            let res = await context.dispatch('putAxios',{url:`theme/`,data: payload})
            if (res.status == 200){
                context.commit('saveTheme',res.data)
                ElMessage({
                    message: 'Mavzu yangilandi',
                    type: 'success',
                })
            }
        },
        async addTheme(context,payload){
            let res = await context.dispatch('postAxios',{url:'theme',data: payload})
            if (res.status == 201) {
                ElMessage({
                    message: 'Yangi mavzular qo`shildi',
                    type: 'success',
                })
                context.commit('newTheme',res.data)
            }
        },
        async addThemes(context,payload){
            let res = await context.dispatch('postAxios',{url:'theme',data: payload})
            if (res.status == 201) {
                ElMessage({
                    message: 'Yangi mavzular qo`shildi',
                    type: 'success',
                })
                context.commit('newThemes',res.data)
            }
        },    
    }
}