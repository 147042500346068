import { ElMessage } from 'element-plus';

export const page = {
  state: () => ({
    pages: [],
    pageTitle: 'Sahifa',
    pageRules: {
      title: [{ required: true, message: 'Sahifa nomini kiriting' }],
      slug: [{ required: true, message: 'Sahifa qisqa matnini kiriting' }],
    },
  }),
  getters: {
    pages({ pages }) {
      return pages;
    },
    pageRules({ pageRules }) {
      return pageRules;
    },
  },
  mutations: {
    addPages(state, payload) {
      state.pages = [payload, ...state.pages];
    },
    updatePage(state, payload) {
      state.pages = state.pages.map((page) => {
        if (payload._id == page._id) return payload;
        return page;
      });
    },
    statusPage(state, payload) {
      state.pages = state.pages.map((page) => {
        if (page._id == payload._id) return payload;
        return page;
      });
    },
    pages(state, payload) {
      state.pages = [...payload];
    },
    deletePage(state,payload){
      state.pages = state.pages.filter(page => page._id !==payload)
    }
  },

  actions: {
    async getPages({ dispatch, commit }, payload) {
      let res = await dispatch('getAxios', { url: 'page', search: payload });
      if (res.status === 200) {
        commit('pages', res.data);
      }
    },
    async getPage({ dispatch }, payload) {
      return await dispatch('getAxios', { url: `page/get/${payload}` });
    },
    async addPage({ dispatch, commit, state }, payload) {
      let res = await dispatch('postAxios', { url: 'page', data: payload });
      if (res.status === 201) {
        commit('addPages', res.data);
        ElMessage({
          message: `Yangi ${state.pageTitle.toLowerCase()} qo'shildi`,
          type: 'success',
        })
        dispatch('getPages');
      }
    },

    async statusPage({ dispatch, commit, state }, payload) {
      let res = await dispatch('getAxios', { url: `page/status/${payload}` });
      if (res.status == 200) {
        commit('statusPage', res.data);
        ElMessage({
          message: `${state.pageTitle} holati o'zgartirildi`,
          type: 'success',
        });
      }
    },

    async savePage({ dispatch, commit, state }, payload) {
      if (payload) {
        let res = await dispatch('putAxios', { url: 'page', data: payload });
        if (res.status === 200) {
          commit('updatePage', res.data);
          ElMessage({
            message: `${state.pageTitle} holati o'zgartirildi`,
            type: 'success',
          })
          dispatch('getPages')
        }
      }
    },

    async removePage({dispatch,commit},payload){
      if (payload){
        let res = await dispatch('deleteAxios',`page/${payload}`)
        if (res.status === 200){
          commit('deletePage',payload)
          ElMessage({
            message: `Sahifa o'chirildi`,
            type: 'warning',
          });
        }
      }
    }
  },
};
