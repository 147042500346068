<template>
  <div :class="`notif ${msg.type}`">
    {{msg.message}}
  </div>
</template>

<script>
export default {
    computed:{
        msg(){
            return this.$store.getters.notification
        }
    }
}
</script>

<style lang="scss">
@import '@/styles/component/notification.scss';
</style>