import { ElMessage } from "element-plus"

export const worker = {
    state: () => ({
        workers: [],
        worker:{},
        workerRules: {
            name: [{ required: true, message: 'Ism-familiyani kiriting' }],
            login: [{ required: true, message: 'Login kiriting' }],
            password: [{ required: true, message: 'Mahfiy kalitini kiriting' }],
            phone: [{ required: true, message: 'Telefon raqam kiriting' }],
            rol: [{ required: true, message: 'Foydalanuvchi turini tanlang' }],
        },
    }),
    getters: {
        workers({workers}){
            return workers
        },
        worker({worker}){
            return worker
        },        
        workerRules({workerRules}){
            return workerRules
        },        
    },
    mutations:{
        workers(state,payload){
            state.workers = payload
        },
        addWorker(state,payload){
            state.workers = [payload,...state.workers]
        },
        deleteWorker(state,payload){
            state.workers = state.workers.filter(worker => worker._id !== payload)
        },
        upWorker(state,payload){
            state.workers = state.workers.map(worker => {
                if (worker._id === payload._id) return payload
                return worker
            })
        }
    },
    actions:{
        getWorker({dispatch},payload){
            return dispatch('getAxios',{
                url: `users/edit/${payload}`
            })
        },
        async getWorkers({dispatch,commit}){
            let res = await dispatch('getAxios',{
                url:'users'
            })
            if (res.status === 200){
                commit('workers',res.data)
            }
        },
        async saveWorker({dispatch,commit},payload){
            let res = await dispatch('putAxios',{
                url: `users`,
                data: payload
            })
            if (res.status == 200){
                commit('upWorker',{...res.data})
            }
        },
        async statusWorker({ dispatch, commit }, payload) {
            let res = await dispatch('getAxios', { url: `users/status/${payload}` });
            if (res.status == 200) {
                commit('upWorker', res.data);
                ElMessage({
                    message: `Xodim holati o'zgartirildi`,
                    type: 'success',
                });
            }
        },
        async addWorker({dispatch,commit},payload){
            let res = await dispatch('postAxios',{
                url:'users',
                data: payload
            })            
            if (res.status==201){
                commit('addWorker',res.data)
            }
            
        }
    }
}