import { ElMessage } from 'element-plus'

export const subject = {
    state: () => ({
        subject: {},
        subjects:[]
    }),
    getters: {
        subject({subject}){
            return subject
        },
        subjects({subjects}){
            return subjects
        },
        activeSubjects({subjects}){
            return subjects.filter(subject => subject.status == 0)
        }
    },    
    mutations:{
        subjects(state,payload){
            state.subjects = payload
        },
        subject(state,payload){
            state.subject = payload
        },
        addSubject(state,payload){
            state.subjects = [payload,...state.subjects]
        },
        deleteSubject(state,payload){
            state.subjects = state.subjects.filter(subject => {
                if (subject._id === payload) return false
                return subject
            })
        },
        updateSubject(state,payload){
            state.subjects = state.subjects.map(subject => {
                if (subject._id == payload._id) return payload 
                return subject
            })
        }        
    },


    actions:{        
        async getSubjects({dispatch,commit},payload){
            let res = await dispatch('getAxios',{
                url: 'subject',
                search: payload
            })
            if (res.status === 200){
                commit('subjects',[...res.data])
            }
        },

        getSubject({dispatch},payload){
            return dispatch('getAxios',{
                url: `subject/get/${payload}`
            })
        },

        async getSubjectsNoTheme(context,payload){
            context.commit('setLoading',true)
            let res = await context.dispatch('getAxios',{url:'subject/notheme',search:payload})
            if (res.status == 200){
                context.commit('setLoading',false)
                context.commit('subjects',res.data)
            }
        },
        

        async addSubject({dispatch,commit},payload){
            let res = await dispatch('postAxios',{
                url: 'subject',
                data: payload
            })
            if (res.status === 201){
                commit('addSubject',{...res.data})
                ElMessage({
                    message: 'Yangi fan qo`shildi',
                    type: 'success',
                })
            }
        },
        async saveSubject({dispatch,commit},payload){
            let res = await dispatch('putAxios',{
                url: 'subject',
                data: payload
            })
            if (res.status === 200){
                commit('updateSubject',{...res.data})
                ElMessage({
                    message: 'Fan yangilandi',
                    type: 'success',
                })
            }
        },   
        async statusSubject({dispatch,commit},payload){
            let res = await dispatch('getAxios',{
                url: `subject/status/${payload}`,
            })
            if (res.status === 200){
                commit('updateSubject',{...res.data})
                ElMessage({
                    message: 'Fan yangilandi',
                    type: 'success',
                })
            }
        },    
        async getCountSubject({dispatch},payload){
            return await dispatch('getAxios',{
                url: `subject/count/${payload}`
            })
        }
    }
}