import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import { settingsList } from './settings';

const routes = [
  {
    path: '/',
    name: 'HomeView',
    component: HomeView,
    children: [
      {
        path: 'bak',
        name: 'bak',
        component: () => import('../views/BakStat.vue'),
      },
      {
        path: 'mag',
        name: 'mag',
        component: () => import('../views/MagStat.vue'),
      },
    ],
  },

  {
    path: '/report',
    name: 'report',
    component: () => import('../views/admin/StatList.vue'),
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('../views/ProfilePage.vue'),
  },
  {
    path: '/reports',
    name: 'reports',
    component: () => import('../views/admin/ReportList.vue'),
    meta: {
      layout: 'admin-layout',
    },
  },
  {
    path: "/testproccess",
    name: "testproccess",
    component: () => import("../views/admin/TestProccess.vue"),
  },
  {
    path: "/testproccess/:id",
    name: "showtestproccess",
    component: () => import("../views/admin/testproccess/ShowTestProccess.vue"),
  },
  {
    path: '/students',
    name: 'students',
    component: () => import('../views/admin/StudentsList.vue'),
    meta: {
      layout: 'admin-layout',
    },
  },
  {
    path: '/payments',
    name: 'kvitansiya',
    component: () => import('../views/admin/KvitansiyaPage.vue'),
    meta: {
      layout: 'admin-layout',
    },
  },

  {
    path: '/masters',
    name: 'masters',
    component: () => import('../views/admin/MastersPage.vue'),
    meta: {
      layout: 'admin-layout',
    },
  },
  {
    path: '/addstudent',
    name: 'addstudent',
    component: () => import('../views/admin/AddStudent.vue'),
    meta: {
      layout: 'admin-layout',
    },
  },

  {
    path: '/student/:id',
    name: 'studentsById',
    component: () => import('../views/admin/student/ShowStudent.vue'),
    meta: {
      layout: 'admin-layout',
    },
  },
  {
    path: '/edit/:id',
    name: 'edit',
    component: () => import('../views/admin/student/EditStudent.vue'),
    meta: {
      layout: 'admin-layout',
    },
  },
  {
    path: '/exam',
    name: 'exam',
    component: () => import('../views/admin/ExamPage.vue'),
    meta: {
      layout: 'admin-layout',
    },
  },

  {
    path: '/exam/:id',
    name: 'showexam',
    component: () => import('../views/admin/exam/ShowExam.vue'),
  },
  {
    path: '/prstat',
    name: 'pr',
    component: () => import('../views/admin/PrivatePage.vue'),
    meta: {
      layout: 'admin-layout',
    },
  },
  settingsList
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
