import { ElMessage } from "element-plus";

export const note = {
  state: () => ({
    notes: [],
  }),
  getters: {
    notes({ notes }) {
      return notes;
    },
  },
  mutations: {
    addNote(state, payload) {
      state.notes = [payload, ...state.notes];
    },
    notes(state, payload) {
      state.notes = [...payload];
    },
  },

  actions: {
    async getNotes({ dispatch, commit }, payload) {
      commit("notes", []);
      let res = await dispatch("getAxios", {
        url: `note/student/${payload}`,
      });
      //   console.log(res.data);
      commit("notes", [...res.data]);
    },
    getNote({ dispatch }, payload) {
      return dispatch("getAxios", {
        url: `note/${payload}`,
      });
    },

    async addNote({ dispatch, commit }, payload) {
      let res = await dispatch("postAxios", {
        url: "note",
        data: payload,
      });

      commit("addNote", { ...res.data });
      ElMessage({
        message: "Saqlandi",
        type: "success",
      });
    },
  },
};
