import { ElMessage } from "element-plus";
import { convertDate } from "../../services/helpers";
import places from "@/dates/places";

export const exam = {
  state: () => ({
    exams: [],
    examTitle: "Imtihon sanalari",
    exam: {
      count: 0,
      students: [],
    },
    examCount: 0,
  }),
  getters: {
    exams({ exams }) {
      return exams;
    },
    activeExam({ exams }) {
      return exams.filter((exam) => exam.part?.status == 0);
    },
    exam({ exam }) {
      exam.students = exam.students.map((student) => {
        student.fullname = `${student.lname} ${student.name} ${student.secondname}`;
        let r = places.regions.find((r) => r.id == student.province);
        if (r) {
          student.province = r.name;
        }
        let d = places.districts.find((r) => r.id == student.district);
        if (d) {
          student.district = d.name;
        }
        student.ball = student.ball > 0 ? student.ball : "";
        student.min = student.msg?.min > 0 ? student.msg.min : "";
        return student;
      });
      return exam;
    },
    examCount({ examCount }) {
      return examCount;
    },
    examTitle({ examTitle }) {
      return examTitle;
    },
  },
  mutations: {
    exam(state, payload) {
      state.exam = payload;
      state.exam.date = convertDate(state.exam.date);
    },
    addExams(state, payload) {
      state.exams = [
        { ...payload, date: convertDate(payload.date) },
        ...state.exams,
      ];
    },
    updateExam(state, payload) {
      state.exams = state.exams.map((exam) => {
        if (payload._id == exam._id) {
          payload.date = convertDate(payload.date);
          return payload;
        }
        return exam;
      });
    },
    statusExam(state, payload) {
      state.exams = state.exams.map((exam) => {
        if (exam._id == payload._id)
          return { ...payload, date: convertDate(payload.date) };
        return exam;
      });
    },
    exams(state, payload) {
      state.exams = [
        ...payload.map((exam) => {
          exam.date = convertDate(exam.date);
          return exam;
        }),
      ];
    },

    updateStudentExam(state, payload) {
      state.exam.students = state.exam.students.map((student) => {
        if (student._id == payload._id) {
          student.msg = {
            ...student.msg,
            file: payload.file,
          };
        }
        return student;
      });
    },

    statusStudentExam(state, payload) {
      state.exam.students = state.exam.students.map((student) => {
        if (student._id == payload._id) {
          return { ...payload, date: convertDate(payload.date) };
        }
        return student;
      });
    },
    changeExamResult(state, payload) {
      state.exam.students = state.exam.students.map((student) => {
        if (student._id == payload._id)
          return { ...student, result: payload.result };
        return student;
      });
    },
    examCount(state, payload) {
      state.examCount = payload;
    },
  },

  actions: {
    async getExams({ dispatch, commit }, payload) {
      commit("setLoading", true);
      let res = await dispatch("getAxios", { url: "exam", search: payload });
      // console.log(res.data.exams);
      commit("exams", res.data.exams);
      commit("examCount", res.data.count);
      commit("setLoading", false);
    },
    async getExam({ dispatch }, payload) {
      return await dispatch("getAxios", { url: `exam/get/${payload}` });
    },

    async getMsg({ dispatch }, payload) {
      return await dispatch("getAxios", { url: `msg/add/${payload}` });
    },

    async sendMessages({ dispatch }, payload) {
       await dispatch("postAxios", {
        url: `msg/sms/${payload.id}`,
        data: payload,
      });
        ElMessage({
          message: `SMS ketdi`,
          type: "success",
        });
        // dispatch('showExam', payload.id);
      
    },

    getExamExcel({ dispatch }, payload) {
      return dispatch("getAxios", {
        url: `exam/excel/${payload._id}`,
        search: payload.search,
      });
    },

    async showExam({ dispatch, commit }, payload) {
      let res = await dispatch("getAxios", {
        url: `exam/show/${payload._id}`,
        search: payload.search,
      });
      // console.log(res.data)
      commit("exam", res.data);
    },
    async addExam({ dispatch, commit, state }, payload) {
      let res = await dispatch("postAxios", { url: "exam", data: payload });
      if (res.status == 200 && res.data == "exist") {
        ElMessage({
          message: `Bunday ${state.examTitle.toLowerCase()} mavjud`,
          type: "warning",
        });
        return false;
      }
      if (res.status === 201) {
        commit("addExams", res.data);
        ElMessage({
          message: `Yangi ${state.examTitle.toLowerCase()} qo'shildi`,
          type: "success",
        });
      }
    },
    async statusExam({ dispatch, commit, state }, payload) {
      let res = await dispatch("getAxios", { url: `exam/status/${payload}` });
      if (res.status == 200) {
        commit("statusExam", res.data);
        ElMessage({
          message: `${state.examTitle} holati o'zgartirildi`,
          type: "success",
        });
      }
    },
    async saveExam({ dispatch, commit, state }, payload) {
      if (payload) {
        let res = await dispatch("putAxios", { url: "exam", data: payload });
        if (res.status === 200) {
          commit("updateExam", res.data);
          ElMessage({
            message: `${state.examTitle} holati o'zgartirildi`,
            type: "success",
          });
        }
      }
    },
    async examStudent({ dispatch, commit }, payload) {
      if (payload) {
        let res = await dispatch("getAxios", {
          url: `exam/examactive/${payload}`,
        });
        if (res.status === 200) {
          commit("statusStudentExam", res.data);
          ElMessage({
            message: `Abiturient imtihonda ishtirok holati o'zgartirildi`,
            type: "success",
          });
        }
      }
    },
    async changeExamStudent({ dispatch }, payload) {
      let res = await dispatch("getAxios", {
        url: `exam/student/${payload._id}/${payload.exam}`,
      });
      if (res.status == 200) {
        ElMessage({
          message: `Abiturient sanasi o'zgartirildi`,
          type: "success",
        });
        dispatch("showExam", payload.old);
      }
    },
    async changeExamStudentList({ dispatch, commit }, payload) {
      let res = await dispatch("putAxios", {
        url: `exam/student/`,
        data: payload,
      });
      if (res.status == 200) {
        ElMessage({
          message: `Abiturient sanasi o'zgartirildi`,
          type: "success",
        });
        commit("upStudentList", res.data);
      }
    },

    async changeBallExam({ dispatch, commit }, payload) {
      let res = await dispatch("putAxios", {
        url: `exam/ball`,
        data: payload,
      });
      if (res.status == 200) commit("statusStudentExam", res.data);

      ElMessage({
        message: `Abiturient bahosi o'zgartirildi`,
        type: "success",
      });
    },
    async changeExamResult({ dispatch, commit }, payload) {
      let res = await dispatch("getAxios", {
        url: `cabinet/result/${payload._id}/${payload.result}`,
      });
      if (res.status == 200) {
        commit("changeExamResult", payload);
        ElMessage({
          message: `Imtihon natijani o'zgartirildi`,
          testproccess: "warning",
        });
      }
    },
  },
};
