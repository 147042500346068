<template>
    <div class="main">
        <user-header />
        <div class="main__row">

            <div class="wrapper">
                <wrapper-header/>
                <router-view />
            </div>
        </div>
    </div>
</template>
  
<script>
import WrapperHeader from '@/components/WrapperHeader.vue'

import UserHeader from '../components/UserHeader.vue'
export default {
    data: () => ({
        look: ''
    }),
    components: { UserHeader, WrapperHeader },
    
}
</script>
  
<style lang="scss"></style>