export const settingsList = {
  path: "/settings",
  name: "settings",
  component: () => import("../views/settings/SettingsPage.vue"),
  children: [
    {
      path: "",
      name: "allsettings",
      component: () => import("../views/settings/AllSettings.vue"),
    },
    {
      path: "part",
      name: "part",
      component: () => import("../views/admin/PartPage.vue"),
    },
    {
      path: "template",
      name: "template",
      component: () => import("../views/settings/TemplatesList.vue"),
    },
    {
      path: "types",
      name: "types",
      component: () => import("../views/admin/TypesPage.vue"),
    },
    {
      path: "access",
      name: "access",
      component: () => import("../views/settings/AccessList.vue"),
    },
    {
      path: "workers",
      name: "workers",
      component: () => import("../views/settings/WorkerPage.vue"),
    },
    {
      path: "subjects",
      name: "subjects",
      component: () => import("../views/settings/SubjectList.vue"),
    },
    {
      path: "tests",
      name: "tests",
      component: () => import("../views/admin/test/AllTest.vue"),
    },
    {
      path: "newtest",
      name: "newtest",
      component: () => import("../views/admin/test/AddTest.vue"),
    },
    {
      path: "edittest/:id",
      name: "edittest",
      component: () => import("../views/admin/test/EditTest.vue"),
    },
    {
      path: "testpacks",
      name: "testpacks",
      component: () => import("../views/admin/testpack/AllTestpack.vue"),
    },
    {
      path: "newtestpack",
      name: "newtestpack",
      component: () => import("../views/admin/testpack/AddTestpack.vue"),
    },
    {
      path: "filial",
      name: "filial",
      component: () => import("../views/settings/FilialList.vue"),
    },
    {
      path: "edittestpack/:id",
      name: "edittestpack",
      component: () => import("../views/admin/testpack/EditTestpack.vue"),
    },
    
    {
      path: "/settings/subject/:id",
      name: "subjectView",
      component: () =>
        import(
          /* webpackChunkName: "subjectView" */ "../views/settings/ThemeList.vue"
        ),
    },
  ],
};
