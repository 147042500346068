
export const modal = {
    state: () => ({
        modal: false
    }),
    getters: {
        modal(state){
            return state.modal
        }
    },
    mutations:{
        modal(state,payload){
            state.modal = payload
        }
    },
    actions:{
        modal(context,payload){
            context.commit('modal',payload)
        }
    }
}